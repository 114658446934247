import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './Header';
import Schedule from './Schedule';
import Directory from './Directory';
import Wellness from './Wellness';
import Partners from './Partners';
import Info from './Info';
import Register from './Register';
import { useEffect } from 'react';
import { useScheduleStore } from './stores/scheduleStore';
import { collection, query, onSnapshot, where } from 'firebase/firestore';
import { db } from './firebase.config';
import { SessionType } from './typings/ScheduleType';
import { useAttendeeStore } from './stores/attendeeStore';
import { AttendeeType } from './typings/AttendeeType';
import LiveList from './LiveList';
import { useSponsorStore } from './stores/sponsorStore';
import { SponsorType } from './typings/SponsorType';
import plat_square from './assets/plat_square.png';
import SanityPage from './components/SanityPage';
import Glossary from './Glossary';
import GiftBag from './GiftBag';

function App() {
  const { setSessions } = useScheduleStore();
  const { setAttendees } = useAttendeeStore();
  const { setSponsors } = useSponsorStore();

  useEffect(() => {
    const getSchedule = () => {
      const q = query(
        collection(db, "platinum_schedule"), 
        where('app_visible', '==', true)
      );
      const unsub = onSnapshot(q, (querySnapshot) => {
        let scheduleItems: SessionType[] = [];
        querySnapshot.forEach((doc) => {
          let docData = doc.data() as SessionType;
          scheduleItems.push(docData);
        });
        setSessions(scheduleItems);
      });
      return unsub;
    };
  
    const getSponsors = () => {
      const q = query(
        collection(db, "platinum_sponsors")
      );

      const unsub = onSnapshot(q, (querySnapshot) => {
        let sponsorLoad: SponsorType[] = [];
        querySnapshot.forEach((doc) => {
          const d = doc.data() as SponsorType;
          d.id = doc.id;
          if(!d.image){
            d.image = plat_square
          }else{
            d.image = "https://storage.googleapis.com/memberhub-ffcf5.appspot.com/" + d.image
          }
          sponsorLoad.push(d);
        });
  
        setSponsors(sponsorLoad);
      });
      return unsub;
    };

    const getAttendees = () => {
      const q = query(
        collection(db, "platinum_attendees"),
        where('trip', 'array-contains', process.env.REACT_APP_MONTH_QUERY)
      );
      const unsub = onSnapshot(q, (querySnapshot) => {
        let attendeeList: AttendeeType[] = [];
        querySnapshot.forEach((doc) => {
          const attendeeData = doc.data() as AttendeeType;
          attendeeData.id = doc.id;
          attendeeList.push(attendeeData);
        });
        setAttendees(attendeeList);
      });
      return unsub;
    };
    
    const unsubscribe = getSchedule();
    const unsubscribe2 = getSponsors();
    const unsubscribe3 = getAttendees();
    return () => {
      if (unsubscribe) unsubscribe();
      if (unsubscribe2) unsubscribe2();
      if (unsubscribe3) unsubscribe3();
    };
  
  }, [])
  

  return (
    <Router>
    <div className="App">
      <Header />
      <main className="bg-platBlack">
          <Routes>
          <Route path="/" element={<Schedule />} />
            <Route path="/schedule" element={<Schedule />} />
            <Route path="/interest" element={<Schedule />} />
            <Route path="/directory" element={<Directory />} />
            <Route path="/experiences" element={<Wellness />} />
            <Route path="/wellness" element={<Wellness />} />
            <Route path="/info" element={<Info />} />
            <Route path="/register" element={<Register />} />
            <Route path="/livelist" element={<LiveList />} />
            <Route path="/olympics" element={<SanityPage pageTitle="Longevity Olympics" sanityId="787dc103-9077-48d1-9041-39375862652e" />} />
            <Route path="/contact" element={<SanityPage pageTitle="A360 Contact Info" sanityId="2d0dc51f-6b06-4b3b-b4d9-166d74ca840b" />} />
            <Route path="/maps" element={<div>Maps coming soon...</div>} />
            <Route path="/partners" element={<Partners />} />
            <Route path="/glossary" element={<Glossary />} />
            <Route path="/giftbag" element={<GiftBag />} />
          </Routes>
        </main>
    </div>
    </Router>
  );
}

export default App;
