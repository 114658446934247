import React from 'react';
import { definitions } from './util/definitions';
import pulsetto from './assets/pulsetto.png';
import somnee from './assets/somnee.png';
import suji from './assets/suji.png';

type Company = {
    id: number;
    name: string;
    website: string;
    logo: string;
    description: string[];
    resources: { name: string; url: string }[];
};

let companies: Company[] = [{
    id:1,
    name:"Pulsetto",
    website:"https://pulsetto.tech",
    logo:pulsetto,
    description:[
    "Pulsetto is a non-invasive vagus nerve stimulator that activates your parasympathetic nervous system to reduce stress and anxiety. Feel calmer, sleep better, live happier.",
    ],
    resources:[{
        name:"Pulsetto Guidebook",
        url:"https://drive.google.com/file/d/1nd8NHgk5dDIMXTED-xRvkh0m4C07VJx-/view?usp=drive_link"
    },{
        name:"Main Tutorial",
        url:"https://youtu.be/js_xuGy_e4g?si=M2VCm5AUpgeb1MvC"
    },{
        name:"Gel Application",
        url:"https://www.youtube.com/watch?v=KQwMlRIJgAg "
    }]
    },
    {
    id:2,
    name:"Somnee",
    website:"https://somneesleep.com",
    logo:somnee,
    description:[
    "Somnee has pioneered the world’s first clinical-grade sleep headband that helps you fall asleep faster for longer and achieve higher quality restorative sleep.",
    "Somnee's headband delivers gentle neurostimulation to safely promote sleep. Your brainwaves start mimicking Somnee's guidance signals and are nudged into optimal sleep pathways. A 15-minute Somnee session before bed is all you need to improve your whole night. At the end of the session, you can take Somnee off and benefit from a great night’s rest - no overnight use needed.",
    "Your Somnee comes with a membership that includes personalized daily stimulation that adapts to your brain patterns, free electrode replenishment during your sleep service membership, and daily reporting."
    ],
    resources:[{
        name:"The Science",
        url:"https://www.youtube.com/watch?v=eFBxTY1lcwc&t=2s"
    }]
    },
    
    {
    id:3,
    name:"Suji",
    website:"https://www.trysuji.com",
    logo:suji,
    description:[
    "Suji is an active recovery device to relieve muscular pains. Feel relief in one session, achieve lasting relief after ten sessions. Controlled via an intuitive mobile app; follow a tailored onboarding and recommended sessions coached by medical experts and champion athletes.",
    ],
    resources:[{
        name:"How-To Video",
        url:"https://www.youtube.com/watch?v=Evius-An7Y4&t=183s "
    },{
        name:"Testimonial",
        url:"https://www.youtube.com/watch?v=1X750KVd1HI "
    }]
    }]


const GiftBag: React.FC = () => {
  const [searchTerm, setSearchTerm] = React.useState('');

  const filteredDefinitions = definitions.filter(definition =>
    definition.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="flex flex-col items-center min-h-dvh max-w-4xl mx-auto px-4 pb-10">
    <div className="py-2 pb-6 text-xl lg:text-2xl text-white font-bold text-center bg-platBlack">
        GIFT BAG
    </div>

    <p className="text-lg text-white mt-4 p-4 bg-darkGold text-left">
        Your gift bag includes innovative wearables picked out by Peter to help you build new daily sleep, diet, and exercise habits that optimize your healthspan:
        <br/><br/>
        See us at the registration table to get 1:1 set up support or schedule an onboarding session directly with a company representative <a href="https://phdventures.typeform.com/platgiftint" className="text-brightGold underline hover:opacity-75">HERE</a>.
    </p>

    <div className="flex flex-col flex-wrap justify-center space-y-6 mt-4">
        {companies.map((sponsor) => (
          <div key={sponsor.id} className="flex flex-col md:flex-row items-center space-x-6 bg-darkGold p-4 rounded-lg shadow-lg w-full max-w-4xl">
            <img
              src={sponsor.logo}
              alt={sponsor.name}
              className="w-56 h-36 object-contain bg-white p-4 mb-4"
            />
            <div className="flex flex-col space-y-2 text-left w-full">
              <div className="text-2xl font-bold text-white">{sponsor.name}</div>
              <a className="text-brightGold text-lg block underline hover:opacity-75" href={sponsor.website} target="_blank" rel="noopener noreferrer">{sponsor.website}</a>
                <div className="text-white text-lg">
                    {sponsor.description.map((desc, index) => (
                        <p key={index}>{desc}</p>
                    ))}
                </div>
              <div>
                <p className="text-white block text-lg font-bold mb-3">Resources:</p>
                <div className="flex flex-col md:flex-row md:space-x-4 space-y-6 md:space-y-0 items-left md:items-center align-top">
               {sponsor.resources.map((resource, index) => (
                <div>
                <a
                    href={resource.url}
                    className="bg-teal font-semibold text-white px-4 py-2 rounded-md shadow hover:opacity-75"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {resource.name}
                  </a>
                </div>
               ))}
               </div>

              </div>
            </div>
          </div>
        ))}
      </div>

    </div>

  );


};

export default GiftBag;

