import React from 'react';
import { definitions } from './util/definitions';


const Glossary: React.FC = () => {
  const [searchTerm, setSearchTerm] = React.useState('');

  const filteredDefinitions = definitions.filter(definition =>
    definition.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="flex flex-col items-center min-h-dvh max-w-2xl mx-auto px-4">
    <div className="py-2 pb-6 text-xl lg:text-2xl text-white font-bold text-center bg-platBlack border-b border-gray-500">
        GLOSSARY
    </div>
      <input
        type="text"
        placeholder="Search terms..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="block text-black w-full px-3 py-2 mb-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-gold focus:border-gold sm:text-sm"
      />

      <ul className="space-y-4">
        {filteredDefinitions.map((definition, index) => (
          <li key={index} className="p-4 rounded bg-darkGold text-left">
            <h3 className="text-xl font-bold mb-2">{definition.name}</h3>
            <p className="text-lg">{definition.definition}</p>
          </li>
        ))}
      </ul>
    </div>

  );


};

export default Glossary;

